import React from 'react'
import PageLayout from '../templates/page-layout'
import { FormContact } from '../components/contact-form'

const ContactPage = () => {
    return (
        <PageLayout>
            <div class="elementor-row">
				<div class="elementor-element elementor-element-52fdda5f elementor-column elementor-col-50 elementor-top-column" data-id="52fdda5f" data-element_type="column">
			<div class="elementor-column-wrap  elementor-element-populated">
					<div class="elementor-widget-wrap">
				<div class="elementor-element elementor-element-653031f5 elementor-widget elementor-widget-heading" data-id="653031f5" data-element_type="widget" data-widget_type="heading.default">
				<div class="elementor-widget-container">
			<h3 class="elementor-heading-title elementor-size-default">Information</h3>		</div>
				</div>
				<div class="elementor-element elementor-element-6443787a elementor-position-left elementor-view-default elementor-vertical-align-top elementor-widget elementor-widget-icon-box" data-id="6443787a" data-element_type="widget" data-widget_type="icon-box.default">
				<div class="elementor-widget-container">
					<div class="elementor-icon-box-wrapper contact-form-icon-holder">
						<div class="elementor-icon-box-icon">
				<span class="elementor-icon elementor-animation-" >
				<i class="fa fa-phone contact-form-icons" aria-hidden="true"></i>				</span>
			</div>
						<div class="elementor-icon-box-content">
				<h3 class="elementor-icon-box-title">
					<span ></span>
				</h3>
								<p class="elementor-icon-box-description">(+27) 012 546 0966</p>
							</div>
		</div>
				</div>
				</div>
				<div class="elementor-element elementor-element-3e71e41 elementor-position-left elementor-view-default elementor-vertical-align-top elementor-widget elementor-widget-icon-box" data-id="3e71e41" data-element_type="widget" data-widget_type="icon-box.default">
				<div class="elementor-widget-container">
					<div class="elementor-icon-box-wrapper contact-form-icon-holder">
						<div class="elementor-icon-box-icon">
				<span class="elementor-icon elementor-animation" >
				<i class="fa fa-envelope contact-form-icons" aria-hidden="true"></i>				</span>
			</div>
						<div class="elementor-icon-box-content">
				<h3 class="elementor-icon-box-title">
					<span ></span>
				</h3>
								<p class="elementor-icon-box-description"><a href="mailto:info@thejosephcompany.co.za">info@thejosephcompany.co.za</a></p>
							</div>
		</div>
				</div>
				</div>
				<div class="elementor-element elementor-element-38d51130 elementor-widget elementor-widget-heading" data-id="38d51130" data-element_type="widget" data-widget_type="heading.default">
				<div class="elementor-widget-container">
			<h3 class="elementor-heading-title elementor-size-default">Address</h3>		</div>
				</div>
				<div class="elementor-element elementor-element-203e71a3 elementor-position-left elementor-view-default elementor-vertical-align-top elementor-widget elementor-widget-icon-box" data-id="203e71a3" data-element_type="widget" data-widget_type="icon-box.default">
				<div class="elementor-widget-container">
					<div class="elementor-icon-box-wrapper contact-form-icon-holder">
						<div class="elementor-icon-box-icon">
				<span class="elementor-icon elementor-animation-" >
				<i class="fa fa-map-marker contact-form-icons" aria-hidden="true"></i>				</span>
			</div>
						<div class="elementor-icon-box-content">
				<h3 class="elementor-icon-box-title">
					<span ></span>
				</h3>
								<p class="elementor-icon-box-description">Plot 293, Protea Road Cnr Methley Road, Pomona AH, 1619</p>
							</div>
		</div>
				</div>
				</div>
						</div>
			</div>
		</div>
				<FormContact />
						</div>
        </PageLayout>
    )
}

export default ContactPage